import { apiUrl } from "config/settings";
import moment from "moment";
import http from 'http'
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import CustomButton from "components/CustomButton";
import { DAYS_OF_WEEK } from "../const";

const RelayCommands = ({data, onClose, setOpenBoostPower}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const bizPath = useSelector((state) => state?.biz?.data?.path);

    const sendCommand = () => {
        if(!data?.isConnected)
            return dispatch(setShowMassage({massage: "MC_IS_DISCONNECTED",type: "error"}));

        dispatch(setShowLoading(true));
        let item = {
            turnOnDate: data?.turnOnDate ? null : moment(),
            turnOffDate: !data?.turnOnDate ? null : moment(),
            boostPower: data?.turnOnDate ? "0" : data?.boostPower,
            command: data?.turnOnDate ? "off" : "on",
        }

            if ("vibrate" in navigator) 
                navigator.vibrate(100);
    
            dispatch(setShowLoading(true));
            http.patch(`${apiUrl}/mc/sendCommand/${bizPath}/${data?.guid}`, item)
            .then((res) => {
                dispatch(setShowLoading(false));
                onClose()
            })
            .catch((error) => {
                dispatch(setShowLoading(false));
                onClose()
            });
      }

    const checkWeeklyTime = () => {
        const day = data?.weeklyTime?.times?.find(i => DAYS_OF_WEEK[i?.day?.toUpperCase()] === moment().day() + 1)
        if(day &&
           day?.isActive &&
           moment().isAfter(day?.end) && !data?.turnOnDate) {
                setOpenBoostPower({open: true, data:data })
                onClose()
        }
        else
            sendCommand()
    }

    return (
        <Stack alignItems='center' spacing={2}>
            <Typography fontWeight='bold'>
                {t(data?.turnOnDate 
                    ? 'TURN_OFF_DATE_INFO_RELAY' 
                    : 'TURN_ON_DATE_INFO_RELAY'
                )}
            </Typography>

            <Stack width='100%' direction='row' spacing={2}>
            <CustomButton title='CLOSE' variant='outlined' fullWidth onClick={onClose}/>
            <CustomButton title='CONTINUE' fullWidth onClick={checkWeeklyTime}/>
            </Stack>
        </Stack>
    )
}
export default RelayCommands