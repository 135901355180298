import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { Close } from "@mui/icons-material";
import CustomIconButton from "./CustomIconButton";
import NullCheck from "./NullCheck";

const CustomDialog = ({ 
  open, 
  onClose, 
  children, 
  title,
  width,
  actionBtn, 
  closeBtn, 
  extraBtn1,
  textFotter,
  fullScreen,
  notCloseClickingBackground,
  maxWidth,
  contentPadding,
 }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: width ?? "auto",
          maxHeight: 600,
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
      open={open}
      onClose={notCloseClickingBackground ? () =>{ } :  onClose}
      fullScreen={fullScreen}
      maxWidth={maxWidth ?? false}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t(title)}</Typography>

          <Stack direction='row' spacing={2}>
          <CustomIconButton size={30} icon={<Close />} onClick={onClose} />
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{padding: contentPadding ?? 2}} dividers>{children}</DialogContent>

      <NullCheck valueForCheck={closeBtn || extraBtn1 || actionBtn || textFotter}>
        <DialogActions >
        <Stack width='100%' spacing={2}>

        <NullCheck valueForCheck={closeBtn || extraBtn1 || actionBtn}>
          <Stack direction='row' justifyContent='end' alignItems='center' spacing={2}>
            <NullCheck valueForCheck={closeBtn && onClose}>
                <CustomButton
                  title={t(closeBtn)}
                  fullWidth
                  variant='outlined'
                  onClick={() => onClose()}
                />
              </NullCheck>

            <NullCheck valueForCheck={extraBtn1}>
                <CustomButton
                  title={t(extraBtn1?.title)}
                  fullWidth
                  color={extraBtn1?.color ?? "primary"}
                  onClick={() => extraBtn1?.onClick()}
                />
            </NullCheck>

            <NullCheck valueForCheck={actionBtn}>
              <CustomButton
                title={t(actionBtn?.title)}
                autoFocus
                fullWidth
                color={actionBtn?.color ?? "primary"}
                onClick={() => actionBtn?.onClick()}
              />
            </NullCheck>
          </Stack>
        </NullCheck>

          <NullCheck valueForCheck={textFotter}>
            <Stack width='100%' alignItems='center' justifyContent='center'>
            <Typography>{textFotter}</Typography>
            </Stack>
          </NullCheck>
          
          </Stack>
        </DialogActions>
      </NullCheck>
    </Dialog>
  );
};
export default CustomDialog;
