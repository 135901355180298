import { Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import CustomButton from "components/CustomButton";
import CustomDialog from "components/CustomDialog"
import { apiUrl } from "config/settings";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setShowMassage } from "store/sysSlice";

const TwoFactorAuth = ({data, setData, control, onSubmit, setTwoFactorAuth}) => {
    const codeInputRef = useRef(null);
    const dispatch = useDispatch()
    const { t } = useTranslation();
    let sendAgainTimerLocal = 30
    const [sendAgainTimer, setSendAgainTimer] = useState(sendAgainTimerLocal)
    let sendAgainInterval = null

    useEffect(() => {
        if(!sendAgainInterval)
            intervalFunc()

        return () => clearIntervalFunc()
    }, [])

    useEffect(() => {
        if (data?.open && codeInputRef?.current) {
            const timer = setTimeout(() => {
                codeInputRef?.current?.focus();
                clearTimeout(timer)
            }, 100);
        } 
    }, [data, codeInputRef])

    const clearIntervalFunc = () =>{
        clearInterval(sendAgainInterval)
        sendAgainInterval = null
    }

    const intervalFunc = () =>{
            sendAgainInterval = setInterval(() => {
                if(sendAgainTimerLocal > 0) {
                    sendAgainTimerLocal = sendAgainTimerLocal - 1
                    setSendAgainTimer(sendAgainTimerLocal)
                }
                else {
                    clearIntervalFunc()
                }
            }, 1000);
    }

    const onClose = () => {
        setTwoFactorAuth(false)
        setData({open: false, empId: null})
    }

    const onKeyDown = (e) =>{
        if(e?.keyCode === 13)
            onSubmit()
    }

    const sendAgain = async () => {
        const item = {employeeId: data?.empId};
        axios.post(`${apiUrl}/employees/sendTwoFactor/code`, item)
        ?.then((res) => { 
            dispatch(setShowMassage({massage: "SEND_CODE_SUCCESS", type: "info"}));
            sendAgainInterval = 30
            setSendAgainTimer(30)
            intervalFunc()
        })
        ?.catch(error =>{})
    }

    return (
        <CustomDialog 
            open={data?.open} 
            onClose={onClose} 
            title='TWO_FACTOR_CODE'
            actionBtn={{title: 'SEND', onClick: ()=> onSubmit()}}
        >
            <Stack alignItems='center' spacing={2}>
                <Stack alignItems='center'>
                    <Typography>
                        {t('TWO_FACTOR_SEND_EMAIL')}
                    </Typography>
                    <Typography>
                        {t('TWO_FACTOR_INPUT')}
                    </Typography>
                </Stack>

                <Stack>
                    <Controller
                        name="twoFactorCode"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                        <TextField
                            inputRef={codeInputRef} 
                            value={value}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                        />
                        )}
                    />

                    <CustomButton 
                        title={sendAgainTimer > 0
                            ? `${`(${sendAgainTimer})`} ${t('SEND_AGAIN_TIMER')}`
                            : "SEND_AGAIN"
                        }
                        disabled={sendAgainTimer > 0}
                        variant='text'
                        fontSize={14}
                        underline
                        onClick={() => sendAgain()} 
                    />
                </Stack>
            </Stack>

        </CustomDialog>
    )
}
export default TwoFactorAuth