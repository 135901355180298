import { Stack, Typography } from "@mui/material"
import CustomButton from "components/CustomButton"
import store from 'store'
import { STEP_TYPE } from "../const"
import { useTranslation } from "react-i18next"
import useMc from "hooks/useMc"
import { setShowLoading, setShowMassage } from "store/sysSlice"
import { useDispatch } from "react-redux"

const ConnectToWifi = ({guid,setStep }) => {
    const { t } = useTranslation();
    const {checkConnected} = useMc()
    const dispatch = useDispatch()

    const checkConnect = async () => {
        const res = await checkConnected(guid)
            if(res?.data?.id === 1){
                const timer = setTimeout(() => {
                    dispatch(setShowLoading(false));
                    const mcStatus = store.getState().mc.status
                    if(mcStatus?.status === 'ok')
                        setStep(STEP_TYPE.MINI_DETAILS)
                    else 
                        dispatch(setShowMassage({massage: "MC_DISCONNECTED",type: "error"}));
                    clearTimeout(timer)
                }, 10000);
            }
    }

    return (
        <Stack spacing={1}>
        
        <Typography variant="h6" fontWeight='bold'>
            {`- ${t('NETWORK_CONNECTION_GUIDE_TITLE')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_1')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_2')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_3')}`}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_4')}`}
        </Typography>
        <Typography 
            fontSize={13} 
            fontWeight='bold'
            sx={{cursor: 'pointer', textDecoration: 'underline' }}
            onClick={()=> window.open('http://192.168.4.1', '_blank')}
            >
            {`${t('NETWORK_CONNECTION_GUIDE_4_1')}`}
        </Typography>
        </Stack>

        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_5')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_6')}`}
        </Typography>
        <Typography>
            {`- ${t('NETWORK_CONNECTION_GUIDE_7')}`}
        </Typography>


          <CustomButton
            title="CHECK_CONNECTED"
            autoFocus
            onClick={checkConnect}
            // onClick={() => setStep(STEP_TYPE.MINI_DETAILS)}
          />
        </Stack>
    )
}
export default ConnectToWifi