import { createSlice, current } from "@reduxjs/toolkit";
import { MC_FAULT_TYPE, MC_TYPE } from "config/const";
import { apiUrl } from "config/settings";
import moment from "moment";
import http from 'http'

const faultCheck = (mc) => {
  let value = null

  const sensor1 = parseInt(mc?.sensors?.find(s => s?.number === '1')?.value)
  const sensor2 = parseInt(mc?.sensors?.find(s => s?.number === '2')?.value)
  if(mc?.turnOffDate && moment().add(-10, "minutes").isAfter(moment(mc?.turnOffDate))) {
    if(sensor1 > (sensor2 + 10))
      value = MC_FAULT_TYPE.COMPRESSOR_NOT_STOP
  }

  else if(mc?.turnOnDate && moment().add(-10, "minutes").isAfter(moment(mc?.turnOnDate))) {
    if(sensor1 > (sensor2 + 5) || sensor1 < (sensor2 + 7))
      value = MC_FAULT_TYPE.LACK_GAS

    else if(sensor1 > (sensor2 + 12))
      value = MC_FAULT_TYPE.CLOGGED_FILTER
  }
  return value
}

const McSlice = createSlice({
  name: "mc",
  initialState: {
    mcList: [],
    status: {},
    acCommands: []
  },
  reducers: {
    setMcList(state, action) {state.mcList = action.payload },

    setSensor(state, action) {
      const dataSplit = action?.payload?.split('/');
      const list = [...state.mcList];
      const newItem = {type: dataSplit[3], number: dataSplit[4], value: dataSplit[5]};
      const mcIdx = list?.findIndex(item => item?.guid === dataSplit[2]) 
      

      const sensorIdx = list[mcIdx]?.sensors 
        ? list[mcIdx]?.sensors?.findIndex(s => s.number === newItem.number)
        : -1

        if(!list[mcIdx]?.isConnected){
          let biz = localStorage.getItem("biz");
          biz = JSON.parse(biz);
          list[mcIdx].isConnected = true
          http.patch(`${apiUrl}/mc/connected/${biz?.path}/${dataSplit[2]}`, {isConnected: true})
          .then((res) => {})
          .catch(error => {})
        }

      if(!list[mcIdx].sensors)
        list[mcIdx].sensors = []

      if (sensorIdx !== -1) {
        list[mcIdx].sensors[sensorIdx] = newItem;
      } else {
        list[mcIdx]?.sensors?.push(newItem);
      }

      list[mcIdx].fault = faultCheck(current(list[mcIdx]))

      state.mcList = list;
    },

    setStatus(state, action) {
      if(Object.keys(action?.payload).length > 0) {
        const dataSplit = action?.payload?.split('/');
        const newItem = {
          bizId: dataSplit[1],
          guid: dataSplit[2],
          status: dataSplit[3],
        };
        state.status = newItem 
      }
    },

    setAcCommands(state, action) {
        state.acCommands = action.payload 
    },

    changeMcCommand (state, action) {
      const data = action.payload
      const list = [...state.mcList];

      const findMcIndex = list.findIndex(mc => mc?.guid === data.guid)
      
      if (findMcIndex !== -1) {
        list[findMcIndex].turnOnDate = data?.turnOnDate
        list[findMcIndex].turnOffDate = data?.turnOffDate
        list[findMcIndex].boostPower = data?.boostPower

        if(list[findMcIndex]?.type === MC_TYPE.AIR_CONDITIONER){
          list[findMcIndex].ac.fanSpeedActive = data?.fanSpeedActive ?? list[findMcIndex]?.ac?.fanSpeedActive
          list[findMcIndex].ac.modeActive = data?.modeActive ?? list[findMcIndex]?.ac?.modeActive
          list[findMcIndex].ac.tempActive = data?.tempActive ?? list[findMcIndex]?.ac?.tempActive
        }
      }
      
      state.mcList = list
    },

    setIsConnectedMc (state, action) {
      const list = state?.mcList ? [...state.mcList] : [];
      const findMcIndex = list.findIndex(mc => mc?.guid === action?.payload?.guid)
      if(findMcIndex !== -1) {
        list[findMcIndex].isConnected = action.payload?.isConnected
        state.mcList = list
      }
    },
  },
});

export const {
  setMcList, 
  setSensor, 
  setStatus, 
  setAcCommands, 
  changeMcCommand,
  setIsConnectedMc,
} = McSlice.actions;

export default McSlice.reducer;
