import { Stack, Typography } from "@mui/material"
import NullCheck from "components/NullCheck";
import { DATE_TIME_FORMAT } from "config/const";
import { changeCase } from "config/functions";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Ac = ({mc}) =>{
    const { t } = useTranslation();
    const [sensor1,setSensor1] = useState(null)
    const [sensor2,setSensor2] = useState(null)
    
    useEffect(() => {
        setSensor1(parseFloat(mc?.sensors?.find(s => s?.number === '1')?.value))
        setSensor2(parseFloat(mc?.sensors?.find(s => s?.number === '2')?.value))
    }, [mc])
    

    const Item = ({title, value}) => {
        return (
            <Stack direction='row' spacing={0.5} alignItems='center'>
            <Typography fontWeight='bold'>{`${t(title)}:`}</Typography>
            <Typography>{value}</Typography>
            </Stack>
        )
    }
    
    return (
        <Stack height='100%' justifyContent='space-between'>
            <Stack padding={1} direction='row' justifyContent='space-between'>
                <Stack alignItems='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('TEMP1')}`}</Typography>
                <Typography fontWeight='bold'>
                    {mc?.sensors? `${sensor1}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>

                <Stack alignItems='center' justifyContent='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('DIFFERENCE')}`}</Typography>
                <Typography fontWeight='bold'>
                    {mc?.sensors? `${Math.abs(parseFloat(sensor1 - sensor2).toFixed(2))}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>

                <Stack alignItems='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('TEMP2')}`}</Typography>
                <Typography fontWeight='bold'>
                    {mc?.sensors ? `${sensor2}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>

            </Stack>

            <Stack  padding={1}>
                <Typography sx={{textDecoration: "underline"}}>
                    {`${t('ACTIVE_DATA')}`}
                </Typography>

                <Item title='STATUS' value={mc?.turnOnDate ? t("ON_MC") : t("OFF_MC") }/>
                <Item title='TEMP_ACTIVE' value={ t(mc?.ac?.tempActive ? changeCase(mc?.ac?.tempActive) : "NO_DATA") }/>
                <Item title='FAN_SPEED' value={ t(mc?.ac?.fanSpeedActive ? changeCase(mc?.ac?.fanSpeedActive) : "NO_DATA") }/>
                <Item title='MODE_ACTIVE' value={ t(mc?.ac?.modeActive ? changeCase(mc?.ac?.modeActive) : "NO_DATA") }/>
            </Stack>

            <Stack
                px={1}
                height={25}
                direction="row"
                borderTop="2px solid"
                borderColor="primary.main"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="secondary.main"
            >
            <NullCheck valueForCheck={mc?.turnOnDate} nullToDiv>
                <Typography textAlign='center' fontWeight='bold' variant="body2">
                    {`${t('TURN_ON_DATE')}: ${moment(mc?.turnOnDate).format(DATE_TIME_FORMAT)}`}
                </Typography>
            </NullCheck>
            </Stack>
    </Stack>
    )
}
export default Ac