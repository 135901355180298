import React from "react";
import { Hive, Menu } from "@mui/icons-material";
import NullCheck from "../components/NullCheck";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LOGO from "assets/logo1.svg"
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Header = ({ setOpenMenu }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const wsIsConnected = useSelector((state) => state.sys?.wsIsConnected);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: "4rem",
        }}
      >
        <Toolbar sx={{pt: isMobile ? 0.5 : 0}}>
          <Stack
            width="100%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <NullCheck nullToDiv valueForCheck={isMobile}>
              <IconButton onClick={() => setOpenMenu((open) => !open)}>
                <Menu color="secondary" />
              </IconButton>
            </NullCheck>

            <Stack alignItems='end' direction='row' spacing={1.5}>

              <Stack direction='row' spacing={1}>
                <Tooltip title={wsIsConnected ? t("WS_IS_CONNECTED") : t("WS_IS_DISCONNECTED") }>
                  <Hive color={wsIsConnected ? "gold" : "error" } />
                </Tooltip>
            
              <Divider
                orientation="vertical"
                flexItem
                sx={{borderRightWidth: 2, bgcolor:'gold.main'}}
                />
              </Stack>
              
            <Stack direction='row' alignItems='end'>
              <Typography color='gold.main' variant="h5">
                  {`MyBee`}
              </Typography>
              <img 
                src={LOGO} 
                width={40} 
                style={{backgroundColor:"#fff", borderRadius:100, padding:1}}
                />
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
