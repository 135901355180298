import "./App.css";
import "./i18n";
import "moment/locale/he";
import { ThemeProvider, THEME_ID, createTheme } from "@mui/material/styles";
import Routers from "./config/Routers";
import rootReducer from "./store";
import { Provider } from "react-redux";
import ApiProvider from "./utils/ApiProvider";
import rtlPlugin from "stylis-plugin-rtl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { heIL } from "@mui/x-date-pickers/locales";
import { heIL as coreHeIL } from "@mui/material/locale";

const theme = createTheme(
  {
    direction: "rtl",
    typography: {
      fontFamily: ["heebo", '"Segoe UI"', "Roboto"].join(","),
      h6: {
        fontSize: 17,
        fontWeight: 600,
      },
      h5: {
        fontSize: 21,
        fontWeight: 700,
      },
      h4: {
        fontSize: 24,
        fontWeight: 700,
      },
      h3: {
        fontSize: 28,
        fontWeight: 700,
      },
    },
    fonts: {
      body: "heebo,system-ui, sans-serif",
      heading: 'heebo, "Avenir Next", sans-serif',
      monospace: "heebo, Menlo, monospace",
    },
    components: {
      MuiTextField: {
        defaultProps: { size: "small" },
          styleOverrides: {
            root: {
              "& input[type='number']::-webkit-outer-spin-button, & input[type='number']::-webkit-inner-spin-button": {
                WebkitAppearance: "none", // Chrome, Edge
                margin: 0,
              },
              "& input[type='number']": {
                MozAppearance: "textfield", // Firefox
              },
            },
          },
        },
      MuiButton: {
        defaultProps: { size: "small" },
      },
    },
    palette: {
      primary: {
        main: "#1b263b",
        // main: "#FFDA78",
        contrastText: "#fff",
      },
      secondary: {
        // main: "#DFF5FF",
        main: "#FFFBDA",
      },
      bg: {
        // main: "#F1F1F1",
        main: "#f6f7f9",
      },
      header: {
        main: "#DCF2F1",
        text: "#365486",
      },
      gold: {
        main:'#ffd700'
      }
    },
    direction: "rtl",
  },
  heIL,
  coreHeIL
);

const cacheRtl = createCache({
  key: "mybee",
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={rootReducer}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="he">
          <CacheProvider value={cacheRtl}>
            <ApiProvider>
              <Routers />
            </ApiProvider>
          </CacheProvider>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
