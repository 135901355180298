import { Checkbox, Divider, Grid, Stack, Typography } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import CustomDialog from "components/CustomDialog";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import http from "http";
import { endpointName } from "../const";
import { apiUrl } from "config/settings";
import useDataManager from "hooks/useDataManager";
import moment from "moment";
import { useForm } from "react-hook-form";
import SelectInput from "components/files/SelectInput";
import useMcCommand from "hooks/useMcCommand";
import { MC_TYPE } from "config/const";

const WeeklyTimes = ({data, setData, list, setList}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {createCommand} = useMcCommand()
    const {handleSubmit, reset, control, formState: { errors }} = useForm();
    const { editItem } = useDataManager();
    const [weeklyTime, setWeeklyTime] = useState(
        [
            {isActive: false, day: "Sun", start: null, end: null},
            {isActive: false, day: "Mon", start: null, end: null},
            {isActive: false, day: "Tue", start: null, end: null},
            {isActive: false, day: "Wed", start: null, end: null},
            {isActive: false, day: "Thu", start: null, end: null},
            {isActive: false, day: "Fri", start: null, end: null},
            {isActive: false, day: "Sat", start: null, end: null},
        ]
    )

    const DAYS_OF_WEEK = {
        SUNDAY: "Sun",
        MONDAY: "Mon",
        TUESDAY: "Tue",
        WEDNESDAY: "Wed",
        THURSDAY: "Thu",
        FRIDAY: "Fri",
        SATURDAY: "Sat",
    }

    const MODES = [
        {name:"COOL", id: "cool"},
        {name:"HEAT", id: "heat"},
        {name:"VENT", id: "vent"},
    ]

    const SPEED_FANS = [
        {name:"LOW", id:"low"},
        {name:"MEDIUM", id:"medium"},
        {name:"HIGH", id:"high"},
    ]

    useEffect(() => {
        reset({
            onSpeedFan: data?.weeklyTime?.onSpeedFan,
            onMode: data?.weeklyTime?.onMode,
            onTemp: data?.weeklyTime?.onTemp,
        })
        if(data?.weeklyTime && data?.weeklyTime?.times?.length > 0) {
            setWeeklyTime(
                weeklyTime?.map(item => {
                    const findDay = data?.weeklyTime?.times?.find(i => i?.day === item?.day)
                    return (
                        {isActive: findDay?.isActive ?? false,
                            day: findDay?.day ?? item?.day,
                            start: findDay?.start ? moment(findDay?.start) : null,
                            end: findDay?.end ? moment(findDay?.end) : null
                        }
                    )
                })
            )
        }
    
    }, [data])

    const chengeEndTime = (time, day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].end = time
            setWeeklyTime(newList)
        }
    }

    const chengeStartTime = (time, day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].start = time
            setWeeklyTime(newList)
        }
    }

    const chengeIsActive = (day) => {
        let newList = [...weeklyTime]
        const findIndex = newList.findIndex(item => item?.day === day)
        if(findIndex !== -1){
            newList[findIndex].isActive = !newList[findIndex]?.isActive
            setWeeklyTime(newList)
        }
    }

    const onClose = () => {
        setData(null)
    }

    const onSubmit = async (values) => {
        
        if(data?.type === MC_TYPE.AIR_CONDITIONER) {
            data.ac.fanSpeedActive = values?.onSpeedFan
            data.ac.modeActive = values?.onMode
            data.ac.tempActive = values?.onTemp
        }

        const onCommand = await createCommand(data, "on", "on")
        const offCommand = await createCommand(data, "off", "off")

        dispatch(setShowLoading(true));
            let item = {
                weeklyTime : {
                    onSpeedFan: values?.onSpeedFan,
                    onMode: values?.onMode,
                    onTemp: values?.onTemp,
                    onCommand: onCommand,
                    offCommand: offCommand,
                    times: weeklyTime
                }
            };
            http.patch(`${apiUrl}/${endpointName}/weeklyTime/${data?.guid}`, item)
            .then((res) => {
                dispatch(setShowLoading(false));
                if(res?.data?.id === 1) {
                  const newItem = {...data, weeklyTime: item?.weeklyTime}
                  const newList = editItem(newItem, list);
                  setList(newList);
                  onClose();
                }
                else {
                  dispatch(setShowMassage({massage: "ERROR",type: "error"}));
                }
            })
            .catch((error) => {
                dispatch(setShowLoading(false));
                dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            });
      };

    return (
        <CustomDialog
        open={data ? true : false}
        width='auto'
        title='WEEKLY_TIMES'
        onClose={onClose}
        actionBtn={{ title:'SAVE', onClick: () => handleSubmit(onSubmit)() }}
        closeBtn='CLOSE'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Typography variant="h6">{t('ACTIVES')}</Typography>
            <Stack direction='row' spacing={1} pb={2}>
                <SelectInput
                    width={150}
                    control={control}
                    errors={errors}
                    name='onTemp'
                    title='ON_TEMP'
                    options={Array.from({ length: 30 - 16 + 1 }, (_, i) => i + 16).map(temp => {
                        return({name:temp, id:`T${temp}`})
                    })}
                />
                <SelectInput
                    width={150}
                    control={control}
                    errors={errors}
                    name='onMode'
                    title='ON_MODE'
                    options={MODES}
                />
                <SelectInput
                    width={150}
                    control={control}
                    errors={errors}
                    name='onSpeedFan'
                    title='ON_SPEED_FAN'
                    options={SPEED_FANS}
                />
            </Stack>

            <Divider /> */}
            
            {/* <Typography variant="h6">{t('TIMES')}</Typography> */}
            <Stack spacing={2}>
                {Object.keys(DAYS_OF_WEEK).map((key, index) => {

                    return (
                    <Grid container columns={3} >
                    <Grid item key={index} gap={2}>

                    <Stack direction='row' alignItems='center'>
                    <Checkbox 
                        checked={weeklyTime[index]?.isActive}
                        onClick={()=> chengeIsActive(DAYS_OF_WEEK[key])}
                    />
                    <Typography>{t(key)}</Typography>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                    <TimePicker 
                        value={weeklyTime[index]?.start} 
                        onChange={(time) => chengeStartTime(time, DAYS_OF_WEEK[key])}
                        disabled={!weeklyTime[index]?.isActive}
                        />

                    <TimePicker 
                        value={weeklyTime[index]?.end} 
                        onChange={(time) => chengeEndTime(time, DAYS_OF_WEEK[key])}
                        disabled={!weeklyTime[index]?.isActive}
                    />
                    </Stack>
                </Grid>
                </Grid>
                )
                })}

            </Stack>
        </form>
        </CustomDialog>
    )
}
export default WeeklyTimes