import { Stack, Typography } from "@mui/material"
import CustomDialog from "components/CustomDialog"
import { PLUGIN_NAME } from "config/const";
import useCheckData from "hooks/CheckData";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setOpenCreateServices } from "store/servicesCallSlice";
import { setShowMassage } from "store/sysSlice";

const ShowFault = ({data, setData}) => {
      const { t } = useTranslation();
      const dispatch = useDispatch();
      const { hasPlugin } = useCheckData();

      const createServiceCall = () => {
        if(hasPlugin(PLUGIN_NAME.SERVICES_CALL)) {
            dispatch(setOpenCreateServices(true));
        }
        else 
            dispatch(setShowMassage({massage: "NO_PLUGIN",type: "info"}));
      }
    
    return (
        <CustomDialog 
            open={data ? true : false }
            onClose={()=> setData(false)}
            title='FAULT'
            actionBtn={{title:"CREATE_SERVICE_CALL", onClick: () => createServiceCall()}}
            closeBtn='CLOSE'
            >
        <Stack alignItems='center'>
        <Typography>{t(data)}</Typography>
        </Stack>
        </CustomDialog>
    )
}
export default ShowFault