import { Stack, Typography } from "@mui/material"
import NullCheck from "components/NullCheck";
import { DATE_TIME_FORMAT } from "config/const";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Relay = ({mc}) => {
    const { t } = useTranslation();
    const [sensor1, setSensor1] = useState(null)
    const [sensor2, setSensor2] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        setData(mc)
        setSensor1(parseFloat(mc?.sensors?.find(s => s?.number === '1')?.value).toFixed(2))
        setSensor2(parseFloat(mc?.sensors?.find(s => s?.number === '2')?.value).toFixed(2))
    }, [mc])

    return (
        <Stack height='100%' justifyContent='space-between'>
            <Stack padding={1} direction='row' justifyContent='space-between'>
                <Stack alignItems='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('TEMP1')}`}</Typography>
                <Typography fontWeight='bold'>
                    {data?.sensors ? `${sensor1}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>

                <Stack alignItems='center' justifyContent='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('DIFFERENCE')}`}</Typography>
                <Typography fontWeight='bold'>
                    {data?.sensors ? `${Math.abs(parseFloat(sensor1 - sensor2).toFixed(2))}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>

                <Stack alignItems='center'>
                <Typography variant="body2" fontWeight='bold'>{`${t('TEMP2')}`}</Typography>
                <Typography fontWeight='bold'>
                    {data?.sensors ? `${sensor2}°` : t("LOADING_TO_DATA")}
                </Typography>
                </Stack>
            </Stack>

            <Stack alignItems='center' spacing={0.5}>
                <Typography variant="h4" fontWeight='bold'>
                    {data?.turnOnDate ? t("ON_MC") : t("OFF_MC") }
                </Typography>

                <NullCheck valueForCheck={parseInt(data?.boostPower) > 0}>
                    <Stack>
                        <Typography textAlign='center' color='green' variant="subtitle2">
                            {`${t('OUTSIDE_BUSINESS_HOURS')}`}
                        </Typography>

                        <Typography textAlign='center' variant="overline">
                            {`${t('OFF')} -  ${moment(data?.turnOnDate).add(parseInt(data?.boostPower), 'minutes').format(DATE_TIME_FORMAT)}`}
                        </Typography>
                    </Stack>
                </NullCheck>
            </Stack>           

            <Stack
                    px={1}
                    height={25}
                    direction="row"
                    borderTop="2px solid"
                    borderColor="primary.main"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor="secondary.main"
                >
                <NullCheck valueForCheck={data?.turnOnDate} nullToDiv>
                    <Typography textAlign='center' fontWeight='bold' variant="body2">
                        {`${t('TURN_ON_DATE')}: ${moment(data?.turnOnDate).format(DATE_TIME_FORMAT)}`}
                    </Typography>
                </NullCheck>
            </Stack>
    </Stack>
    )
}
export default Relay