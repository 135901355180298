import { Divider, Stack } from "@mui/material"
import CustomButton from "components/CustomButton"
import CustomDialog from "components/CustomDialog"
import NullCheck from "components/NullCheck"
import { MC_FAULT_TYPE, MC_TYPE } from "config/const"
import ShowFault from "containers/controlAndMonitoring/components/ShowFault"
import Ac from "containers/controlAndMonitoring/mcType/Ac"
import Relay from "containers/controlAndMonitoring/mcType/Relay"
import { useState } from "react"

const McItem = ({mc, setMc}) =>{
const [faultData, setFaultData] = useState(null)
    const onClose = () =>{
        setMc({open: false, data: null})
    }
    
    return (
        <>
        <CustomDialog
            open={mc?.open}
            onClose={onClose}
            title={mc?.data?.name}
            contentPadding={0}
        >
                <Stack height={250} spacing={2}>
                    <NullCheck valueForCheck={mc?.data?.fault}>
                        <Stack padding={1} spacing={1}>
                            <CustomButton
                                color='error' 
                                title="FAULT" 
                                onClick={()=> setFaultData(mc?.data?.fault)}
                                />
                                
                            <Divider/>
                        </Stack>
                    </NullCheck>

                    {mc?.data?.type === MC_TYPE.AIR_CONDITIONER
                        ? <Ac mc={mc?.data} />
                        : mc?.data?.type === MC_TYPE.RELAY
                        ? <Relay mc={mc?.data} />
                        : null 
                    }
                </Stack>
        </CustomDialog>

    <ShowFault data={faultData} setData={setFaultData} />
        </>
    )
}
export default McItem