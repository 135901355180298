import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { apiUrl, SITE_KEY_V2, SITE_KEY_V3 } from "config/settings";
import { setIsLogin, setUser } from "store/userSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setBiz } from "store/bizSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomButton from "components/CustomButton";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import NullCheck from "components/NullCheck";
import ReCAPTCHA from "react-google-recaptcha";
import useDataManager from "hooks/useDataManager";
import {deleteCookie,removeRandomChars,setCookie} from "config/functions";
import {
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import useWebSocket from "hooks/useWebSocket";
import TwoFactorAuth from "./TwoFactorAuth";

const Login = () => {
  const reRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userName } = useParams();
  const { connected } = useWebSocket();
  const {getInitData} = useDataManager()
  const { handleSubmit, control } = useForm();
  const isLogin = useSelector((state) => state?.userSettings?.isLogin);

  const [anim, setAnim] = useState(!isLogin);
  const [showPassword, setShowPassword] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState({open: false, empId: null});

  const getUser = localStorage.getItem("userName");
  const recaptcha_v2 = localStorage.getItem("recaptcha");
  const [hasRecaptcha_v2, setHasRecaptcha_v2] = useState(recaptcha_v2);

  useEffect(() => {
    if (!isLogin) {
      localStorage.removeItem("user");
      localStorage.removeItem("biz");
      deleteCookie("authAnalytics");
    }

    if (!hasRecaptcha_v2) {
      setSuccessCaptcha(true);
    }

    dispatch(setShowLoading(false));
  }, []);

  const onSubmit = async (values) => {
    if ((userName && !values?.userName) || !values?.passWord || !successCaptcha)
      return;

    if(twoFactorAuth && !values?.twoFactorCode)
      return dispatch(setShowMassage({massage: "TWO_FACTOR_CODE_REQUIRED",type: "error"}));

    dispatch(setShowLoading(true));

    if (values?.rememberMe) 
      localStorage.setItem("userName", userName ?? values?.userName);
    else 
      localStorage.removeItem("userName");
    
    const reToken = await reRef?.current?.execute();

    const user = {
      userName: userName ?? values?.userName,
      passWord: values?.passWord,
      twoFactorCode: twoFactorAuth ? values?.twoFactorCode : null ,
      reToken: reToken,
    };

    axios.post(`${apiUrl}/employees/login`, user).then((response) => {

      if(response?.data?.hasTwoFactor) {
        setTwoFactorCode({open: true, empId: response?.data?.empId})
        setTwoFactorAuth(true)
        setHasRecaptcha_v2(false);
        dispatch(setShowLoading(false));
        return
      }

        const user = response?.data?.user;
        let token = response.headers.get("Authorization");

        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${removeRandomChars(token, 4)}`;
        }

        axios.get(`${apiUrl}/business/settings/${user?.bizId}`).then((res) => {
          const biz = res?.data;

          if (biz) {
            setAnim(false);
            axios.defaults.headers.common["BizId"] = `${biz.id}`;
            setCookie("authAnalytics", token);

            localStorage.removeItem("recaptcha");
            localStorage.setItem("biz", JSON.stringify(biz));
            localStorage.setItem("user", JSON.stringify(user));
            dispatch(setUser(user));
            dispatch(setBiz(biz));
            getInitData()
            connected(biz?.path)
            dispatch(setShowMassage({massage: "LOGIN_SUCCESS",type: "success"}));
            setTimeout(() => {
              dispatch(setShowLoading(false));
              navigate("/");
              dispatch(setIsLogin(true));
            }, 400);
          }
        });
      })
      .catch((error) => {
        console.error("Auth error");

        if (!hasRecaptcha_v2 && !twoFactorAuth) 
          setSuccessCaptcha(false);
        if(!twoFactorAuth) {
          setHasRecaptcha_v2(true);
          localStorage.setItem("recaptcha", "1");
        }
      
        dispatch(setShowLoading(false));
        dispatch(setShowMassage({massage: "LOGIN_ERROR",type: "error"}));
      });
  };

  return (
    <Stack
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{
        overflow: "hidden",
        scrollbarWidth: "none",
      }}
    >
      <Zoom in={anim}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            padding={3}
            spacing={3}
            bgcolor="white"
            borderRadius={2}
            border="3px solid black"
          >
            <Typography textAlign="center" variant="h6" fontWeight="bold">
              {t("LOGIN")}
            </Typography>

            <Stack spacing={2}>
              <NullCheck valueForCheck={!userName}>
                <Controller
                  name="userName"
                  control={control}
                  defaultValue={getUser ?? ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      defaultValue={getUser ?? ""}
                      placeholder={t("USER_NAME")}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </NullCheck>

              <Stack spacing={1}>
                <Controller
                  name="passWord"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type={showPassword ? "text" : "passWord"}
                      placeholder={t("PASSWORD")}
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword((data) => !data)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="rememberMe"
                  control={control}
                  defaultValue={getUser ? true : false}
                  render={({ field: { onChange, value } }) => (
                    <Stack alignItems="center" direction="row" gap={0.5}>
                      <Checkbox
                        defaultChecked={getUser ? true : false}
                        sx={{ padding: 0 }}
                        disableRipple
                        checke={value}
                        onClick={onChange}
                        size="small"
                      />
                      <Typography fontSize={12}>{t("REMEMBER_ME")}</Typography>
                    </Stack>
                  )}
                />
              </Stack>


            </Stack>

            <NullCheck valueForCheck={hasRecaptcha_v2}>
              <ReCAPTCHA
                sitekey={SITE_KEY_V2}
                size="normal"
                onChange={() => setSuccessCaptcha(true)}
              />
            </NullCheck>

            <CustomButton title="CONNECT" submit />

            {/* <Stack alignItems="center" direction="row" spacing={1}>
                <Typography fontSize={12}>{t("HAS_SIGNIN")}</Typography>

                <CustomButton
                  title="SIGNIN_NOW"
                  fontSize={12}
                  underline
                  onClick={() => navigate("/Signup")}
                  variant="text"
                />
              </Stack> */}

            <Stack alignItems="center">
              <Typography fontSize={12}>
                This site is protected by reCAPTCHA and the Google
              </Typography>
              <Typography fontSize={12}>
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and
                <a href="https://policies.google.com/terms">
                  Terms of Service
                </a>{" "}
                apply.
              </Typography>

              <ReCAPTCHA
                style={{ visibility: "hidden" }}
                sitekey={SITE_KEY_V3}
                ref={reRef}
                size="invisible"
              />
            </Stack>
          </Stack>

          <NullCheck valueForCheck={twoFactorCode?.open}>
            <TwoFactorAuth
              data={twoFactorCode} 
              setData={setTwoFactorCode}
              setTwoFactorAuth={setTwoFactorAuth}
              control={control}
              onSubmit={() => handleSubmit(onSubmit)()}
            />
              </NullCheck>
        </form>
      </Zoom>
    </Stack>
  );
};
export default Login;
