import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Divider, Stack } from "@mui/material";
import GroupData from "./components/GroupData";
import CustomTabs from "components/CustomTabs";
import Header from "./Header";
import McBoostPower from "./commands/McBoostPower";

const ControlAndMonitoring = () => {
  const list = useSelector((state) => state?.mc.mcList);
  const [mcList, setMcList] = useState(list)
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedArea, setSelectedArea] = useState('all')

  const TABS = [
    {id: 0, title: 'ALL'},
    {id: 1, title: 'ON_MC'},
    {id: 2, title: 'OFF_MC'},
    {id: 3, title: 'FAULTS'},
  ]

  useEffect(() => {
    filterList(selectedTab, selectedArea)
  }, [list, selectedArea, selectedTab])
  
  const filterList = (tabId, areaId ) => {
    let newList = [...list]

    /** Area Filter */
    if(areaId === 'all')
      newList = newList
    else
    newList = newList.filter(mc => mc?.area?.id === areaId)

    /** tab filter */
    if(tabId === TABS[0]?.id)
      newList = newList
    else if(tabId === TABS[1]?.id)
      newList = newList.filter(mc => mc?.turnOnDate)
    else if(tabId === TABS[2]?.id)
      newList = newList.filter(mc => !mc?.turnOnDate)
    else if(tabId === TABS[3]?.id)
      newList = newList.filter(mc => mc?.fault || !mc?.isConnected)

    setMcList(newList)
  }

  return (
    <CustomPage 
      header={<Header selectedArea={(area) => setSelectedArea(area)}/>} 
      hidePadding
    >
      <Stack height="100%">
        <CustomTabs tabList={TABS} sticky divider callBackFunc={(tab) => setSelectedTab(tab)}/>
        <Divider/>
        <GroupData list={mcList}/>
      </Stack>
    </CustomPage>
  );
};
export default ControlAndMonitoring;
