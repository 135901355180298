import { AC_COMMANDS_TYPE } from "config/const"

const useMcCommand = () =>{

    const createCommand = async (data, type, value) => {
    let command = ""

    if(data?.ac?.commands?.sysName === AC_COMMANDS_TYPE.ELECTRA_1)
        command = await create_electra_1(data, type, value)
    else if(data?.ac?.commands?.sysName === AC_COMMANDS_TYPE.TADIRAN_1)
        command = await create_tadiran_1(data, type, value)
    else if(data?.ac?.commands?.sysName === AC_COMMANDS_TYPE.TORNADO_1)
        command = await create_tornado_1(data, type, value)

    return command
    }

    /** Electra */
    const create_electra_1 = async (data, type, next) => {
        let command = "";
    
        const start = data?.ac?.commands?.startCommand
        const end = data?.ac?.commands?.endCommand
    
        const on = data?.ac?.commands?.power?.on
        const off = data?.ac?.commands?.power?.off
        const fanSpeedActive = data?.ac?.commands?.fanSpeed[data?.ac?.fanSpeedActive ?? "medium"]
        const tempActive = data?.ac?.commands?.temperatures[data?.ac?.tempActive ?? "T22"].command
        const modeActive = data?.ac?.commands?.modes[data?.ac?.modeActive ?? "vent"]
    
        if(type === 'on') {
            const onComm =  data?.ac?.modeActive === 'vent' ? on["comm3"] : on["comm1"]
            command = `${start}${onComm}${modeActive[fanSpeedActive]}${tempActive}${end}`
        } else if(type === 'off') {
            const offComm =  data?.ac?.modeActive === 'vent' ? off["comm3"] : off["comm1"]
            command = `${start}${offComm}${modeActive[fanSpeedActive]}${tempActive}${end}`
        } else if(type === 'fan') {
            const isVent =  data?.ac?.modeActive === 'vent'
            const newFan = modeActive[next]
            command = `${start}${isVent ? "1" : ""}${newFan}${tempActive}${end}`
        } else if(type === 'mode') {
            const isVent =  next === 'vent'
            const newMode = data?.ac?.commands?.modes[next][fanSpeedActive]
            command = `${start}${isVent ? "1" : ""}${newMode}${tempActive}${end}`
        } else if(type === 'temp') {
            const isVent =  data?.ac?.modeActive === 'vent'
            const newTemp = data?.ac?.commands?.temperatures[next].command
            command = `${start}${isVent ? "1" : ""}${modeActive[fanSpeedActive]}${newTemp}${end}`
        }
        return command
    }

    /** Tadiran */
    const create_tadiran_1 = async (data, type, next) => {
        let command = "";
    
        const start = data?.ac?.commands?.startCommand
        const off = data?.ac?.commands?.power?.off
        const fanSpeedActive = data?.ac?.commands?.fanSpeed[data?.ac?.fanSpeedActive ?? "medium"]
        const tempActive = data?.ac?.commands?.temperatures[data?.ac?.tempActive ?? "T22"].command
        const modeActive = data?.ac?.commands?.modes[data?.ac?.modeActive ?? "vent"]
    
        if(type === 'on') {
            command = `${start}${tempActive}${modeActive[fanSpeedActive]}`
        } else if(type === 'off') {
            const offComm =  data?.ac?.modeActive === 'cool' 
                ? off["comm1"] 
                    : data?.ac?.modeActive === 'heat'
                        ? off["comm2"]
                            : off["comm3"]
            command = `${start}${tempActive}${offComm}`
        } else if(type === 'fan') {
            const newFan = modeActive[next]
            command = `${start}${tempActive}${newFan}`
        } else if(type === 'mode') {
            const newMode = data?.ac?.commands?.modes[next][fanSpeedActive]
            command = `${start}${tempActive}${newMode}`
        } else if(type === 'temp') {
            const newTemp = data?.ac?.commands?.temperatures[next].command
            command = `${start}${newTemp}${modeActive[fanSpeedActive]}`
        }
        return command
    }

    /** Tadiran */
    const create_tornado_1 = async (data, type, next) => {
        let command = "";
    
        const start = data?.ac?.commands?.startCommand
        const off = data?.ac?.commands?.power?.off
        const tempActive = data?.ac?.commands?.temperatures[data?.ac?.tempActive ?? "T22"].command
        const modeActive = data?.ac?.commands?.modes[data?.ac?.modeActive ?? "vent"]
        const fanSpeedActive = data?.ac?.commands?.fanSpeed[data?.ac?.fanSpeedActive ?? "medium"]
        let speed;
        let mode;

        if(data?.ac?.modeActive !== "vent"){
            const modeActive = data?.ac?.commands?.modes[data?.ac?.modeActive][fanSpeedActive].split("-")
            speed = modeActive[0]
            mode = modeActive[1]
        }

        if(type === 'on') {
            const isVent = data?.ac?.modeActive === "vent"
            if(isVent)
                command = `${start}${modeActive[fanSpeedActive]}`
            else
                command = `${start}${speed}${tempActive}${mode}`
        } else if(type === 'off') {
            const offComm =  data?.ac?.modeActive === 'cool' 
                ? off["comm1"] 
                    : data?.ac?.modeActive === 'heat'
                        ? off["comm2"]
                            : off["comm3"]

            command = `${start}${offComm}`
        } else if(type === 'fan') {
            const isVent = data?.ac?.modeActive === "vent"
            if(isVent) {
                command = `${start}${modeActive[next]}`
            }
            else{
                const newFan = modeActive[next].split("-")
                command = `${start}${newFan[0]}${tempActive}${newFan[1]}`
            }

        } else if(type === 'mode') {
            if(next ===  "vent") {
                command = `${start}${data?.ac?.commands?.modes[next][fanSpeedActive]}`
            }
            else {
                const newMode = data?.ac?.commands?.modes[next][fanSpeedActive].split("-")
                command = `${start}${newMode[0]}${tempActive}${newMode[1]}`
            }
        } else if(type === 'temp') {
            const isVent = data?.ac?.modeActive === "vent"
            if(!isVent){
                const newTemp = data?.ac?.commands?.temperatures[next].command
                command = `${start}${speed}${newTemp}${mode}`
            }
        }

        return command
    }
    
    return{createCommand}
    // return{create_electra_1, create_tadiran_1, create_tornado_1}
}
export default useMcCommand