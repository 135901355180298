export const lineText = (text) => {
  const newText = text
    ?.split("\n")
    ?.map((str) => <p style={{ margin: 0 }}>{str}</p>);
  return newText;
};

export const listTolineText = (list) => {
  const newText = list?.map((text) =>
    text?.split("\n")?.map((str) => <p style={{ margin: 0 }}>{str}</p>)
  );
  return newText;
};

export const findKeyByValue = (obj, targetValue) => {
  for (let key in obj) {
    if (obj[key] === targetValue) {
      return key;
    }
  }
  return null;
};

export const sum = (arr, type) => {
  let sumNum = 0;
  if (arr?.length > 0) {
    arr?.map((service) => {
      if (!isNaN(service[type])) {
        sumNum = sumNum + service[type];
      }
    });
  }
  return sumNum;
};

//** Cookies */
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts?.length === 2) return parts.pop().split(";").shift();
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export const currencyFormat = (number) =>
  new Intl.NumberFormat("en-IL", {
    style: "currency",
    currency: "ILS",

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 1, // (causes 2500.99 to be printed as $2,501)
  }).format(number);


  export const removeRandomChars = (str, num) => {
  let result = "";
  let i = 0;

  while (i < str?.length) {
    result += str[i];
    i++;
    if ((i + 1) % (num + 1) === 0) {
      i++;
    }
  }

  return result;
};

export const  sortArray = (array, key = null, descending = false) => {
   return array.sort((a, b) => {
       const valueA = key ? a[key] : a;
       const valueB = key ? b[key] : b;

       if (valueA < valueB) return descending ? 1 : -1;
       if (valueA > valueB) return descending ? -1 : 1;
       return 0;
   });
}

export const shuffleArray = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // בחירת אינדקס אקראי
    [arr[i], arr[j]] = [arr[j], arr[i]]; // החלפת איברים
  }
  return arr;
}

export const changeCase = (str, type = "upperCase") => {
  return str.split('')
    .map(char => {
      if (type === "lowerCase") {
        return char.toLowerCase();
      } else if(type === "upperCase") {
        return char.toUpperCase();
      }
    })
    .join('');
}
