import { useRef } from 'react';
import { wsUrl } from 'config/settings';
import { useDispatch, useSelector } from 'react-redux';
import { changeMcCommand, checkMcConnected, setIsConnectedMc, setSensor, setStatus } from 'store/mcSlice';
import { v4 as uuidv4 } from 'uuid';
import { setWsIsConnected } from 'store/sysSlice';
import store from "store"

const useWebSocket = () => {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.sys?.wsIsConnected);
  let ws = null
  let reconnectedTimer = null

  const DATA_TYPE = {
    SENSOR: 's',
    STATUS: 'status',
  }


  const connected = (bizPath) => {
    ws = new WebSocket(`${wsUrl}?bizId=${bizPath}-${uuidv4()}`);
    socket.current = ws;
    
    ws.onopen = () => {
      dispatch(setWsIsConnected(true))
      console.log('connected')
      if(reconnectedTimer)
        reconnected("off")
    }

    ws.onclose = () => {
      console.log('disConnected')
      dispatch(setWsIsConnected(false))
      disconnected()
      reconnected("on")
    }

    // קבלת הודעות מהשרת
    ws.onmessage = (event) => {
      const dataType = event?.data?.split('/')[0]
      const mcData = event?.data?.split('/')[2]

      if(dataType === DATA_TYPE.SENSOR) {
        dispatch(setSensor(event?.data))
      }
      else if(dataType === DATA_TYPE.STATUS){
        dispatch(setStatus(event?.data))
      }
      else if(dataType === 'callBackCommand'){
        dispatch(changeMcCommand(JSON.parse(mcData)))
      }
      else if(dataType === 'McConnected' || dataType === 'McDisConnected' ){
        const item = {isConnected: dataType === 'McConnected' ? true : false, guid: mcData}
        dispatch(setIsConnectedMc(item))
      }
    };
  }

  const disconnected = () =>{
    if(socket){
      // socket?.current?.end();
      ws= null
      socket.current = null;
    }
  }

  const reconnected = (type) => {
    if(type === "off") {
        clearInterval(reconnectedTimer)
        reconnectedTimer = null
    }
    else if("on" && !reconnectedTimer){
      const stateList = store.getState();
      const path = stateList?.biz?.data?.path

      reconnectedTimer = setInterval(() => {
        if(!isConnected)
          connected(path)
      }, 15000);
    }
  }

  const sendMessage = (msg) => {
    if (isConnected) {
        socket.current.send(msg);
    }
  };

  return {connected, disconnected, sendMessage}
};

export default useWebSocket;