import { Info } from "@mui/icons-material";
import {
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog";
import { setShowMassage } from "store/sysSlice";
import { useDispatch } from "react-redux";

const TextInput = ({
  control,
  rows,
  title,
  name,
  disabled,
  required,
  errors,
  width,
  type,
  validation,
  defaultValue,
  standard,
  autoFocus,
  min,
  max
}) => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const refInput = useRef(null);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openMassge, setOpenMassge] = useState(false);
  const error = errors ? errors[name]?.ref?.name : false;
  const massge = errors ? errors[name]?.message : "";

  const checkNmber = (value) => {
      let checked = false
      if(!isNaN(value)){
        if(!value && value === "")
          checked = true
  
        else if(min || max) {
          if(min && !max && value >= min)
            checked = true
  
          else if(max && !min && value <= max)
            checked = true
  
          else if(max && min && value >= min && value <= max)
            checked = true
        }
        else{
          return true
        }
      }
      return checked
  } 

  return (
    <Stack direction={standard ? "row" : "column"} spacing={standard ? 1 : 0}>
      <Stack alignItems="end" direction="row" gap={0.5}>
        <Typography fontSize={standard ? 16 : 12}>
          {`${required ? "*" : ""}${title ? `${t(title)}:` : ""}`}
        </Typography>
      </Stack>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: required ? t("REQUIRED") : false,
          pattern: {
            value: validation?.regex,
            message: t(validation?.massge),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            defaultValue={defaultValue}
            sx={{ width: isMobile ? "100%" : width ? width : 200 }}
            inputRef={refInput}
            variant={standard ? "standard" : "outlined"}
            error={error === name ? true : false}
            disabled={disabled}
            autoFocus={autoFocus ? true : false}
            multiline={rows ? true : false}
            rows={rows}
            value={value}
            type={type ?? "text"}
            inputProps={{
              min: min ,
              max: max,
            }}
            InputProps={{
              endAdornment: massge ? (
                <Tooltip title={massge}>
                  <IconButton onClick={() => setOpenMassge(true)}>
                    <Info color="error" fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : null,
            }}
            onChange={(e) => {
              if(type === "number") {
                if(checkNmber(e.target.value))
                  onChange(e.target.value)
                else {
                  if(max && !min)
                    return dispatch(setShowMassage({
                      massage: `${t('NUMBER_MUST_BE_LESS_THAN')} ${max + 1}`
                      ,type: "info"
                    }));
                  else if(min && !max)
                    return dispatch(setShowMassage({
                      massage: `${t('NUMBER_MUST_BE_GREATER_THAN')} ${min + 1}`
                      ,type: "info"
                    }));
                  else if(min && max)
                    return dispatch(setShowMassage({
                      massage: `${t('NUMBER_MUST_BE_BETWEEN')} ${min} - ${max}`
                      ,type: "info"
                    }));
                }
                
             }
              else
                onChange(e.target.value)
            }}
          />
        )}
      />
      {massge ? (
        <CustomDialog
          title="ERROR"
          open={openMassge}
          onClose={() => setOpenMassge(false)}
        >
          {massge}
        </CustomDialog>
      ) : null}
    </Stack>
  );
};
export default TextInput;
