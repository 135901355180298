import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoData = () => {
    const { t } = useTranslation();
    return (
        <Stack 
            width='100%' 
            justifyContent='center' 
            alignItems='center'
          >
          <Typography variant="h6" fontWeight='bold'>
            {t("NO_DATA")}
          </Typography>
        </Stack>
    )
}
export default NoData