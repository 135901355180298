import { ArrowDropDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AccordionItem = ({btn, setOpenMenu, openMenu, locationPage }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Accordion sx={{ boxShadow: 0}}>
            <AccordionSummary expandIcon={openMenu ? <ArrowDropDown  /> : null}>
                <Stack 
                    width='100%' 
                    direction='row' 
                    alignItems='center' 
                    justifyContent='center'
                    spacing={1}
                >
                {btn?.icon}
                {openMenu ? <ListItemText primary={t(btn?.title)}/> : null}
                </Stack>
            </AccordionSummary>

                {btn?.childrens?.map((children, index) => {
                    return(
                        <AccordionDetails key={index} sx={{padding: 0}}>
                              <ListItem
                                sx={{bgcolor: locationPage === children.path ? "secondary.main" : ""}}
                                disablePadding
                              >
                                <ListItemButton
                                  onClick={() => {
                                    navigate(`${children.path}`);
                                    if (isMobile) setOpenMenu(false);
                                  }}
                                >
                                  <ListItemIcon>{children.icon}</ListItemIcon>
                                  {openMenu ? <ListItemText primary={t(children.title)}/> : null}
                                </ListItemButton>
                              </ListItem>
                        </AccordionDetails>
                    )
                })}
        </Accordion>
    )
}
export default AccordionItem