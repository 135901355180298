import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomDataGrid = ({
  columns,
  list,
  setItem,
  defaultSort,
  hideFooter,
  getCellClassName,
  checkboxSelection,
  onRowSelectionModelChange,
  columnVisibilityModel,
  onColumnOrderChange,
}) => {
  const { t } = useTranslation();
  const [sortModel, setSortModel] = useState(defaultSort ?? []);

  return (
    <DataGrid
      localeText={{ noRowsLabel: t("NO_DATA") }}
      getCellClassName={getCellClassName}
      hideFooter={hideFooter ? true : false}
      columnVisibilityModel={columnVisibilityModel}
      onColumnOrderChange={onColumnOrderChange}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      onRowClick={(e) => setItem(e?.row)}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
      rowReordering
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onRowSelectionModelChange}
      disableColumnMenu
      columns={columns}
      rows={list}
    />
  );
};
export default CustomDataGrid;
