import { useTranslation } from "react-i18next";
import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch } from "react-redux";
import http from "http";
import { setShowLoading } from "store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import {IconButton,Stack} from "@mui/material";
import Commands from "./details/Commands";
import {  Edit, PendingActions, TouchApp } from "@mui/icons-material";
import CustomButton from "components/CustomButton";
import { endpointName } from "./const";
import NullCheck from "components/NullCheck";
import CreateMc from "./create";
import { findKeyByValue, sortArray } from "config/functions";
import { MC_TYPE } from "config/const";
import WeeklyTimes from "./details/WeeklyTimes";

const MicroControllers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [areas, setAreas] = useState([]);
  const [acCommands, setAcCommands] = useState([]);
  const [mcCommands, setMcCommands] = useState(null);
  const [weeklyTimes, setWeeklyTimes] = useState(null);
  const [openCreateMc, setOpenCreateMc] = useState(false);

  useEffect(() => {
    getData();
    getAreas();
    getAcCommands();
  }, []);

  const getAreas = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/area`).then((res) => {
      setAreas(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getAcCommands = () =>{
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/acCommands/select`).then((res) => {
      setAcCommands(sortArray(res?.data?.data, "name"));
      dispatch(setShowLoading(false));
    });
  }

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="end">
          <CustomButton
            title="NEW"
            onClick={() => setOpenCreateMc(true)}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{pagination: {paginationModel: {pageSize: 100}}}}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "name",
            headerName: t("NAME"),
            width: 300,
            filterable: false,
          },
          {
            field: "guid",
            headerName: t("GUID"),
            width: 300,
            filterable: false,
          },
          {
            field: "type",
            headerName: t("TYPE"),
            width: 100,
            filterable: false,
            renderCell: (value) => t(findKeyByValue(MC_TYPE, value?.row?.type))
          },
          {
            field: "Commands",
            headerName: t("COMMANDS"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <NullCheck valueForCheck={value?.row?.type === MC_TYPE.AIR_CONDITIONER}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setMcCommands(value?.row);
                    }}
                  >
                    <TouchApp />
                  </IconButton>
                </NullCheck>
              );
            },
          },
          {
            field: "WeeklyTimes",
            headerName: t("WEEKLY_TIMES"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setWeeklyTimes(value?.row);
                  }}
                >
                  <PendingActions />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((mc) => {
          return {
            ...mc,
            id: mc?.id,
            name: mc?.name,
            guid: mc?.guid,
            type: mc?.type,
            isLinked: mc?.isLinked
          };
        })}
      />

      <NullCheck valueForCheck={weeklyTimes}>
        <WeeklyTimes
          list={list}
          setList={setList}
          data={weeklyTimes}
          setData={setWeeklyTimes}
          />
      </NullCheck>

      <NullCheck valueForCheck={mcCommands}>
        <Commands
          list={list}
          setList={setList}
          acCommands={acCommands}
          data={mcCommands}
          setData={setMcCommands}
          />
      </NullCheck>
      
      <NullCheck valueForCheck={openCreateMc}>
        <CreateMc
          areas={areas}
          list={list}
          setList={setList}
          open={openCreateMc}
          setOpen={setOpenCreateMc}
          />
      </NullCheck>
    </CustomPage>
  );
};
export default MicroControllers;
