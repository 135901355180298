import { CastConnected } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material"
import { MC_FAULT_TYPE, MC_TYPE } from "config/const";
import { findKeyByValue, shuffleArray } from "config/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import McItem from "./McItem";
import NullCheck from "components/NullCheck";

const McMonitoring = () => {
    const { t } = useTranslation();
    const data = useSelector((state) => state?.mc.mcList);
    const biz = useSelector((state) => state?.biz?.data);
    const [list, setList]= useState([])
    const [mc, setMc]= useState({open: false, data:null})

    useEffect(() => {
        if(biz.isExample) {
            let arr =[] 
            Array.from({ length: 30 - 16 + 1 }, (_, i) => i + 16).map(index => {
                arr.push(...data)
            })
            setList(shuffleArray(arr))
        }
        else {
            setList(data)
        }
    }, [data])


    return (
        <>
            <Stack 
                useFlexGap 
                justifyContent='center' 
                sx={{ flexWrap: 'wrap' }} 
                direction='row' 
                spacing={1}
            >
                {list?.map((mc, index) => {
                    const turnOnDate = mc?.turnOnDate
                    return(
                        <Stack
                            width={100}
                            onClick={() => setMc({open: true, data: mc})}
                            sx={{
                                cursor: "pointer", 
                                opacity: turnOnDate || mc?.fault ? 1 : 0.5
                            }}
                            direction="row"
                            alignItems="center"
                            padding={1}
                            justifyContent='center'
                            bgcolor={ mc?.fault === MC_FAULT_TYPE.CLOGGED_FILTER
                                    ? 'warning.main' 
                                    : mc?.fault
                                    ? 'error.main'
                                    : 'primary.main'
                            }
                        >
                            <Stack  color="primary.contrastText">
                                <Typography textAlign='center' fontWeight="bold">
                                    {`${t(findKeyByValue(MC_TYPE, mc?.type))} - ${index + 1}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
            
            <NullCheck valueForCheck={mc?.open}>
                <McItem mc={mc} setMc={setMc}/>
            </NullCheck>
        </>
    )
}
export default McMonitoring