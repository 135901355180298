import { EMPLOYEES, MANAGER } from "../../config/roles";

export const endpointNmae = "servicesCall";

export const PMT_TYPE_ID = {
  PAY: 1,
  NOT_PAY: 2,
  SERVICE: 3,
};
export const PMT_TYPE = [
  { id: PMT_TYPE_ID.PAY, name: "PAY" },
  { id: PMT_TYPE_ID.NOT_PAY, name: "NOT_PAY" },
  { id: PMT_TYPE_ID.SERVICE, name: "SERVICE" },
];

export const ST_TYPE_ID = {
  OPEN: 1,
  CLOSE: 2,
  WAITING: 3,
};
export const ST_TYPE = [
  { id: ST_TYPE_ID.OPEN, name: "OPEN" },
  { id: ST_TYPE_ID.CLOSE, name: "CLOSE" },
  { id: ST_TYPE_ID.WAITING, name: "WAITING" },
];

export const COLUMNS_LIST = [
  { name: "clientCallNumner", role: EMPLOYEES, title: "CLIENT_CALL_NUMNER", sortNum: 1},
  { name: "name", role: EMPLOYEES, title: "CONTACT_NAME", sortNum: 2},
  { name: "company", role: EMPLOYEES, title: "COMPANY", sortNum: 3},
  { name: "addr", role: EMPLOYEES, title: "ADDR", sortNum: 4},
  { name: "tel", role: EMPLOYEES, title: "TEL", sortNum: 5},
  { name: "notes", role: EMPLOYEES, title: "NOTES", sortNum: 6},
  { name: "workDt", role: EMPLOYEES, title: "WORK_DT", sortNum: 7},
  { name: "created", role: EMPLOYEES, title: "CREATED", sortNum: 8},
  { name: "providers", role: EMPLOYEES, title: "PROVIDERS", sortNum: 9},
  { name: "contentWithItems", role: EMPLOYEES, title: "CONTENT_WITH_ITEMS", sortNum: 10},
  { name: "price", role: MANAGER, title: "PRICE", sortNum: 11},
];
