import { useTranslation } from "react-i18next";
import CustomPage from "components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "http";
import { setDeleteMassage, setShowLoading } from "store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "components/CustomButton";
import { endpointName } from "./const";
import { setAcCommands } from "store/mcSlice";
import {
  Box,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const AcCommands = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const list = useSelector((state) => state?.mc?.acCommands);
  const [AcCommandDetails, setAcCommandDetails] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      dispatch(setAcCommands(res?.data?.data));
      dispatch(setShowLoading(false));
    });
  };

  const acCommandDetailsHandle = (item) => {
    setAcCommandDetails(item);
  };

  const deleteAcCommands = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_AC_COMMANDS",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              getData();
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <Box></Box>
          <CustomButton
            title="NEW"
            onClick={() => acCommandDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        rowSelection={() => acCommandDetailsHandle("ie")}
        columns={[
          {
            field: "brand",
            headerName: t("BRAND"),
            flex: 10,
            filterable: false,
          },
          {
            field: "model",
            headerName: t("MODEL"),
            flex: 10,
            filterable: false,
          },
          {
            field: "version",
            headerName: t("VERSION"),
            flex: 10,
            filterable: false,
          },
          {
            field: "year",
            headerName: t("YEAR"),
            flex: 10,
            filterable: false,
          },
          {
            field: "protocol",
            headerName: t("PROTOCOL"),
            flex: 10,
            filterable: false,
          },
          {
            field: "bits",
            headerName: t("BITS"),
            flex: 10,
            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (values) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setAcCommandDetails(values?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton onClick={(e) => deleteAcCommands(row.id)}>
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list?.map((AcCommand) => {
          return { ...AcCommand, id: AcCommand?._id };
        })}
      />

      {AcCommandDetails ? (
        <Details
          acCommand={AcCommandDetails}
          setList={setAcCommandDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default AcCommands;
