import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomButton from "components/CustomButton"
import CustomDialog from "components/CustomDialog"
import { apiUrl } from "config/settings"
import http from "http";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading } from "store/sysSlice";

const McBoostPower = ({data, setData}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const bizPath = useSelector((state) => state?.biz?.data?.path);
    
    const onClose = () =>{
        setData(false)
    }

    const onSubmit = (minit) => {
        dispatch(setShowLoading(true));
        http.patch(`${apiUrl}/mc/boostPower/${bizPath}/${data?.data?.guid}`, {boostPower: minit})
        .then((res) => {
            dispatch(setShowLoading(false));
            onClose()
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });

    }

    return (
        <CustomDialog
            open={data?.open}
            onClose={onClose}
            title='OUTSIDE_WORKING_HOURS'
        >
            <Stack justifyContent='center' spacing={2}>
                <Typography textAlign='center' fontWeight='bold'>
                    {t('OUTSIDE_WORKING_HOURS_MSG')}
                </Typography>

                <Typography textAlign='center'>
                    {t('OUTSIDE_WORKING_HOURS_MSG_1')}
                </Typography>
                
                <Stack 
                width='100%' 
                direction={isMobile ? 'column' :'row'} 
                justifyContent='center'
                spacing={2}
                >
                <CustomButton
                    variant='outlined'
                    onClick={() => onSubmit(60)} 
                    title='ONE_HOUR'
                />
                <CustomButton 
                    variant='outlined'
                    onClick={() => onSubmit(120)} 
                    title='TWO_HOURS'
                />
                <CustomButton 
                    variant='outlined'
                    onClick={() => onSubmit(180)} 
                    title='THREE_HOURS'
                />
                
                <CustomButton 
                    variant='outlined'
                    onClick={() => onSubmit(240)} 
                    title='FOUR_HOURS'
                />
                </Stack>
            </Stack>

        </CustomDialog>
    )
}
export default McBoostPower