import CustomDataGrid from "../../../components/CustomDataGrid";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MeetingRoom } from "@mui/icons-material";

const TableData = ({  list, selectBiz }) => {
  const { t } = useTranslation();

  return (
    <CustomDataGrid
      defaultSort={[{ field: "date", sort: "asc" }]}
      setItem={(biz) => selectBiz(biz?.id)}
      columns={[
        {
          field: "path",
          headerName: t("PATH"),
          width: 100,
          filterable: false,
        },
        {
          field: "name",
          headerName: t("NAME"),
          width: 150,
          filterable: false,
          renderCell: (values) => {
            return(
              <span 
              style={{
                  color: values?.row?.isExample ? '#155E95' : 'clack' ,
                  fontWeight:'bold'
                }}
              >
                {values?.row?.name}
              </span>
            )
          }
        },
        {
          field: "addr",
          headerName: t("ADDR"),
          width: 150,
          filterable: false,
        },
        {
          field: "tel",
          headerName: t("TEL"),
          width: 200,
          filterable: false,
        },
        {
          field: "email",
          headerName: t("EMAIL"),
          width: 200,
          filterable: false,
        },
        {
          field: "navigateBusiness",
          headerName: t("NAVIGATE_BUSINESS"),
          width: 100,
          filterable: false,
          renderCell: (values) => {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  selectBiz(values?.row?.id);
                }}
              >
                <MeetingRoom />
              </IconButton>
            );
          },
        },
      ]}
      list={list?.map((item) => {
        return {
          ...item,
          id: item?._id,
          name: item?.name,
          isExample: item?.isExample,
        };
      })}
    />
  );
};
export default TableData;
