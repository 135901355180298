import { useForm } from "react-hook-form";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "config/settings";
import { endpointName } from "../const";
import useDataManager from "hooks/useDataManager";
import { useEffect } from "react";
import {Stack,} from "@mui/material";
import CustomDialog from "components/CustomDialog";
import SelectInput from "components/files/SelectInput";
import CustomButton from "components/CustomButton";

const Commands = ({ data, setData, list, setList, acCommands }) => {
  const { editItem } = useDataManager();
  const dispatch = useDispatch();
  const {reset, watch,handleSubmit, control, formState: { errors }} = useForm();
  const commands = watch('commands')

  useEffect(() => {
    if (data) reset({commands: data?.ac?.commands?.id});
  }, [data]);
  
  const onClose = () => {
    setData(false);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));
    
      let item = {acCommand: values.commands};
        http.patch(`${apiUrl}/${endpointName}/acCommand/${data?.guid}`, item)
          .then((res) => {
            dispatch(setShowLoading(false));

            if(res?.data?.id === 1) {
              let newItem = {...data}
              newItem.ac.commands.id = values.commands

              const newList = editItem(newItem, list);
              setList(newList);
              onClose();
            }
            else {
              dispatch(setShowMassage({massage: "LOGIN_ERROR",type: "error"}));
            }
          })
          .catch(() => {
            dispatch(setShowLoading(false));
            dispatch(setShowMassage({massage: "LOGIN_ERROR",type: "error"}));
          });
  };
  
  const sendTestAc = () => {
    if(!commands)
      return dispatch(setShowMassage({massage: "COMMAND_NOE_FOUND_ERROR",type: "error"}));
     
    const obj = { guid: data?.guid,commands: commands }
    http.post(`${apiUrl}/mc/testCommand`, obj).then(res => {
      dispatch(setShowMassage({massage: "TEST_COMMAND_SUCCESS",type: "success"}));
    })
    .catch(error => {
      dispatch(setShowMassage({massage: "ERROR",type: "error"}));
    })
  }

  return (
    <CustomDialog
      open={data ? true : false}
      width='auto'
      title='COMMANDS'
      onClose={onClose}
      actionBtn={{ title:'SAVE', onClick: () => handleSubmit(onSubmit)() }}
      closeBtn='CLOSE'
    >
      <form 
        style={{ overflow: "hidden" }} 
        onSubmit={handleSubmit(onSubmit)}
      >
           <Stack direction='row' alignItems='end' spacing={2}>
            <SelectInput
              width={230}
              name='commands'
              title='SELECT_COMMAND'
              control={control}
              errors={errors}
              required
              options={acCommands}
            />

            <CustomButton 
              title='TEST'
              height={40}
              onClick={sendTestAc}
            />
          </Stack>
      </form>
      </CustomDialog>
  );
};
export default Commands;