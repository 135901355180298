import http from 'http'
import { useDispatch, useSelector } from 'react-redux'
import { setShowLoading, setShowMassage } from 'store/sysSlice'


const useMc = () =>{
const dispatch = useDispatch()
const bizPath = useSelector((state) => state?.biz?.data?.path);

    const send = async (topic, msg) => {
        const data = {topic: topic, msg: msg}
        try {
            const res = await http.post(`mqttMc/send`, data)
            return res
        } 
        catch (error) {
        dispatch(setShowMassage({massage: "ERROR",type: "error"}));
        return error    
        }

    }

    const checkConnected = async (guid) => {
        try {
            dispatch(setShowLoading(true));
            const data = {topic: guid, msg: `status/${bizPath}/${guid}`}
            const res = await http.post(`mqttMc/send`, data)
                if(res?.data?.id !== 1)
                    dispatch(setShowMassage({massage: "ERROR",type: "error"}));
                return res
        
        } catch (error) {
            dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            return error
        }
    }
    
    return{send, checkConnected }
}
export default useMc