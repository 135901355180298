import TextInput from "components/files/TextInput";
import { useForm } from "react-hook-form";
import ImageInput from "components/files/ImageInput";
import { useEffect, useState } from "react";
import http from "http";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "config/settings";
import useForms from "hooks/useForms";
import { useTranslation } from "react-i18next";
import { celIsraeli, email } from "utils/validations";
import CustomButton from "components/CustomButton";
import useDataManager from "hooks/useDataManager";
import SelectInput from "components/files/SelectInput";
import { ROLES, ROLES_ID } from "config/roles";
import NullCheck from "components/NullCheck";
import SwitchInput from "components/files/SwitchInput";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { setEmployees } from "store/employeesSlice";

const Details = ({ empId, setEmpId, departments }) => {
  const { addItem, editItem } = useDataManager();
  const dispatch = useDispatch();
  const { formData } = useForms();
  const { t } = useTranslation();
  const list = useSelector((state) => state?.employees?.list);
  const {reset, handleSubmit, control, setError, clearErrors, watch, formState: { errors }} = useForm();
  const [image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const isBoAllowed = watch("isBoAllowed");

  useEffect(() => {
    if (empId && empId !== "new") {
      getEmployees(empId);
    }
  }, [empId]);

  const getEmployees = (id) => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/employees/${id}`).then((res) => {
      dispatch(setShowLoading(false));
      setData(res?.data);
      reset(res?.data);
    });
  };

  const onClose = () => {
    setEmpId(false);
  };

  const onSubmit = (values) => {

    if(values?.hasTwoFactor && !values?.email)
      return dispatch(setShowMassage({massage: "TWO_FACTOR_NEED_EMAIL_ERROR",type: "error"}));
    


    dispatch(setShowLoading(true));

    let data = {
      fn: values.fn,
      ln: values.ln,
      tel: values.tel,
      // hasWeb: values?.hasWeb ? true : false,
      hasWeb: false,
      isBoAllowed: values?.isBoAllowed ? true : false,
      userRole: values.role,
      email: values.email ?? "",
      path: values.path,
      bizRole: values.bizRole ?? "",
    };

    if (values?.department !== "") data.department = values?.department;
    if (values.passWord && values?.isBoAllowed) data.passWord = values.passWord;
    if (values?.isBoAllowed) data.hasTwoFactor = values.hasTwoFactor;
    if (values?.userName && values?.isBoAllowed) data.userName = values?.userName;
    if (image) data.employeesImage = image;

    try {
      /** Edit Employees */
      if (empId !== "new") {
        http.patch(`${apiUrl}/employees/${empId}`, formData(data))
          .then((res) => {
            dispatch(setShowLoading(false));

            if(res?.data?.id === 1) {
              data._id = empId;
              const newList = editItem(data, list);
              dispatch(setEmployees(newList))
              onClose();
            }

            else {
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }

          })
      }
      
      /** Add Employees */
      else {
        http.post(`${apiUrl}/employees`, formData(data)).then((res) => {
            dispatch(setShowLoading(false));

            if (res?.data?.id === 1) {
              data.id = res?.data?.itemId;
              const newList = addItem(data, list);
              dispatch(setEmployees(newList))
              onClose();
            } 

            else if (res?.data?.id === -5) {
              dispatch(setShowLoading(false));
              dispatch(setShowMassage({massage: "PATH_EXISTS",type: "error"}));
              setError("path", {
                type: "path",
                message: t("PATH_EXISTS"),
              });
            } 

            else if (res?.data?.id === -6) {
              dispatch(setShowMassage({massage: "PATH_EXISTS",type: "error"}));
              setError("userName", {
                type: "userName",
                message: t("USER_NAME_EXISTS"),
              });
            } 

            else {
              dispatch(setShowMassage({massage: "ERROR",type: "error"}));
            }
          })
      }
    } 
    catch (error) {
      dispatch(setShowMassage({massage: "ERROR",type: "error"}));
      dispatch(setShowLoading(false));
    }

  };

  return (
    <Dialog
      open={empId ? true : false}
      onClose={onClose}
      maxWidth="md"
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Typography variant="h6">{t("EMPLOYEE_DETAILS")}</Typography>
        </DialogTitle>

        <DialogContent>
          <Stack
            height="100%"
            width="100%"
            direction={{ xs: "column", md: "row" }}
            spacing={2}
          >
            <Stack padding={2} spacing={2}>
              <TextInput
                required
                errors={errors}
                name="fn"
                title="FN"
                control={control}
              />
              <TextInput
                required
                errors={errors}
                name="ln"
                title="LN"
                control={control}
              />
              <TextInput name="bizRole" title="BIZ_ROLE" control={control} />

              <TextInput
                required
                errors={errors}
                validation={celIsraeli}
                name="tel"
                title="TEL"
                control={control}
              />
              <TextInput
                validation={email}
                errors={errors}
                name="email"
                title="EMAIL"
                control={control}
              />
            </Stack>

            <Stack padding={2} spacing={2}>
              <TextInput
                disabled={data?.path ? true : false}
                // validation={path}
                errors={errors}
                required
                name="path"
                title="EMPLOYEES_PATH"
                control={control}
              />

              <NullCheck valueForCheck={data?.role !== ROLES_ID.SU_ADMIN}>
                <SelectInput
                  name="role"
                  title="ROLE"
                  required
                  control={control}
                  defaultValue={data?.role ?? ""}
                  errors={errors}
                  options={ROLES}
                />
              </NullCheck>

              <SelectInput
                name="department"
                title="DEPARTMENT"
                control={control}
                defaultValue={data?.department ?? ""}
                errors={errors}
                options={departments.map((department) => {
                  return {
                    id: department._id,
                    name: `${department?.name}`,
                  };
                })}
              />

              <ImageInput
                errors={errors}
                clearErrors={clearErrors}
                name="employeeImg"
                title="EMPLOYEE_IMG"
                setCb={setImage}
                src={data?.employeesImage ?? null}
              />
            </Stack>

            <Stack padding={2} spacing={2}>
              {/* <SwitchInput
                name="hasWeb"
                title="HAS_WEB"
                width="100%"
                errors={errors}
                control={control}
              /> */}

              <SwitchInput
                name="isBoAllowed"
                title="IS_BO_ALLOWED"
                width="100%"
                errors={errors}
                control={control}
              />

              <NullCheck valueForCheck={isBoAllowed}>
                <TextInput
                  required={isBoAllowed ? true : false}
                  errors={errors}
                  name="userName"
                  title="USER_NAME"
                  disabled={data?.userName ? true : false}
                  control={control}
                />

                <TextInput
                  type="passWord"
                  errors={errors}
                  required={isBoAllowed && empId === "new" ? true : false}
                  name="passWord"
                  title="PASSWORD"
                  control={control}
                />

                <SwitchInput
                  name="hasTwoFactor"
                  title="HAS_TWO_FACTOR"
                  width="100%"
                  errors={errors}
                  control={control}
              />

              </NullCheck>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
