import { MC_FAULT_TYPE, MC_TYPE } from "config/const";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {findKeyByValue} from "config/functions";
import Ac from "../mcType/Ac";
import Commands from "../commands";
import { CastConnected, Report } from "@mui/icons-material";
import Relay from "../mcType/Relay";
import NoData from "components/NoData";
import NullCheck from "components/NullCheck";
import ShowFault from "./ShowFault";
import CustomIconButton from "components/CustomIconButton";
import McBoostPower from "../commands/McBoostPower";

const GroupData = ({list}) => {
  const { t } = useTranslation();
  const [mcGuid, setMcGuid] = useState(null)
  const [faultData, setFaultData]  = useState(null)
  const [openBoostPower, setOpenBoostPower] = useState(false)
  
  return (
    <Stack 
      height='100%' 
      padding={2} 
      spacing={2} 
      direction="row" 
      useFlexGap flexWrap="wrap"
    >
      {list?.length > 0
        ? list?.map((mc) => {
            return (
              <Stack
                width={{ xs: "100%", md: 250 }}
                height={270}
                justifyContent="space-between"
                border="2px solid"
                borderColor="primary.main"
              >
                <Box height='85%'>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent='space-between'
                    bgcolor={mc?.fault === MC_FAULT_TYPE.CLOGGED_FILTER
                      ? 'warning.main' 
                      : mc?.fault
                        ? 'error.main'
                        : 'primary.main'
                    }
                  >
                    <Typography 
                      padding={0.5} 
                      color="primary.contrastText" 
                      fontWeight="bold"
                    >
                      {`${mc.name} - ${t(findKeyByValue(MC_TYPE, mc.type))}`}
                    </Typography>

                    <Stack 
                      height='100%' 
                      direction='row' 
                      spacing={1} 
                      padding={0.8}
                     bgcolor='primary.contrastText'>
                    <NullCheck valueForCheck={mc?.fault}>
                      <Tooltip title={t(mc?.fault)}>
                        <CustomIconButton
                        icon={<Report/>}
                        iconColor='error'
                        onClick={()=> setFaultData(mc?.fault)}
                        bgcolor='none'
                        size={25}
                        />
                      </Tooltip>
                    </NullCheck>

                    <CustomIconButton
                      icon={<CastConnected/>}
                      iconColor={mc.isConnected ? 'success' : 'error'}
                      bgcolor='none'
                      size={25}
                      />
                    </Stack>
                  </Stack>

                  <Stack 
                    height='100%' 
                    onClick={() => setMcGuid(mc?.guid)}
                    sx={{ cursor: "pointer"}}
                  >
                    {mc.type === MC_TYPE.AIR_CONDITIONER
                    ? <Ac mc={mc} />
                    : mc.type === MC_TYPE.RELAY
                    ? <Relay mc={mc} />
                    : null 
                    }
                  </Stack>
                </Box>

                <Stack
                  px={1}
                  direction="row"
                  borderTop="2px solid"
                  borderColor="primary.main"
                  alignItems="center"
                  justifyContent="space-between"
                  bgcolor="secondary.main"
                >
                </Stack>
              </Stack>
            );
          })
        : <NoData/>
      }

    <NullCheck valueForCheck={mcGuid}>
      <Commands 
        mcGuid={mcGuid} 
        setMcGuid={setMcGuid} 
        setOpenBoostPower={setOpenBoostPower} 
      />
    </NullCheck>

    <NullCheck valueForCheck={faultData}>
      <ShowFault 
        data={faultData} 
        setData={setFaultData} 
      />
    </NullCheck>

    <NullCheck valueForCheck={openBoostPower}>
      <McBoostPower
        data={openBoostPower}
        setData={setOpenBoostPower}
        />
    </NullCheck>

    </Stack>
  );
};
export default GroupData;
