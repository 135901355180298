import { Button, Divider, Stack, Typography } from "@mui/material"
import CustomIconButton from "components/CustomIconButton"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import http from "http"
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoading, setShowMassage } from "store/sysSlice";
import useMcCommand from "hooks/useMcCommand";
import { 
    AcUnit,
    AutofpsSelect,
    ExpandLess,
    ExpandMore, 
    KeyboardDoubleArrowUp, 
    LightMode, 
    ModeStandby, 
    SettingsPower, 
    WindPower 
} from "@mui/icons-material"

const AcCommands = ({data}) =>{
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const {createCommand} = useMcCommand()
//   const {create_electra_1, create_tadiran_1, create_tornado_1} = useMcCommand()
  const bizPath = useSelector((state) => state?.biz?.data?.path);

  const [fanEmpOpacity, setFanEmpOpacity] =useState(0.1)

  const [fanAutoFanOpacity, setFanAutoFanOpacity] =useState(0.1)
  const [fanHighOpacity, setFanHighOpacity] =useState(0.1)
  const [fanMediumOpacity, setFanMediumOpacity] =useState(0.1)
  const [fanLowOpacity, setFanLowOpacity] =useState(0.1)
  
  const [modeAutoOpacity, setModeAutoOpacity] =useState(0.1)
  const [modeVentOpacity, setModeVentOpacity] =useState(0.1)
  const [modeHeatOpacity, setModeHeatOpacity] =useState(0.1)
  const [modeCoolOpacity, setModeCoolOpacity] =useState(0.1)

  useEffect(() => {
    if(data) {
        if(!data?.turnOnDate) {
            setModeAutoOpacity(0.1)
            setModeVentOpacity(0.1)
            setModeHeatOpacity(0.1)
            setModeCoolOpacity(0.1)
            setFanAutoFanOpacity(0.1)
            setFanHighOpacity(0.1)
            setFanMediumOpacity(0.1)
            setFanLowOpacity(0.1)
            setFanEmpOpacity(0.1)
        }
        else {
            setFanEmpOpacity(1)
        }

        if(data?.turnOnDate) {
            if(data?.ac?.fanSpeedActive === "low" ) {
                setFanLowOpacity(1)
                setFanMediumOpacity(0.1)
                setFanHighOpacity(0.1)
                setFanAutoFanOpacity(0.1)
            } else if(data?.ac?.fanSpeedActive === "medium" ) {
                setFanLowOpacity(1)
                setFanMediumOpacity(1)
                setFanHighOpacity(0.1)
                setFanAutoFanOpacity(0.1)
            } else if(data?.ac?.fanSpeedActive === "high" ) {
                setFanLowOpacity(1)
                setFanMediumOpacity(1)
                setFanHighOpacity(1)
                setFanAutoFanOpacity(0.1)
            } else if(data?.ac?.fanSpeedActive === "autoFan" ) {
                setFanAutoFanOpacity(1)
                setFanLowOpacity(0.1)
                setFanMediumOpacity(0.1)
                setFanHighOpacity(0.1)
            }
            
            if(data?.ac?.modeActive === "cool" ) {
                setModeCoolOpacity(1)
                setModeHeatOpacity(0.1)
                setModeVentOpacity(0.1)
                setModeAutoOpacity(0.1)
            } else if(data?.ac?.modeActive === "heat" ) {
                setModeHeatOpacity(1)
                setModeCoolOpacity(0.1)
                setModeVentOpacity(0.1)
                setModeAutoOpacity(0.1)
            } else if(data?.ac?.modeActive === "vent" ) {
                setModeVentOpacity(1)
                setModeHeatOpacity(0.1)
                setModeCoolOpacity(0.1)
                setModeAutoOpacity(0.1)
            } else if(data?.ac?.modeActive === "auto" ) {
                setModeAutoOpacity(1)
                setModeVentOpacity(0.1)
                setModeHeatOpacity(0.1)
                setModeCoolOpacity(0.1)
            }
        }
    }
  }, [data])

const sendCommand = (item) => {
    if(item?.turnOnDate) {
        if ("vibrate" in navigator) 
            navigator.vibrate(100);

        dispatch(setShowLoading(true));
        http.patch(`${apiUrl}/mc/sendCommand/${bizPath}/${data?.guid}`, item)
        .then((res) => {
            dispatch(setShowLoading(false));
        })
        .catch((error) => {
            dispatch(setShowLoading(false));
        });
    }
    else {
        dispatch(setShowMassage({massage: "ERROR",type: "error"}));
    }
  }

const onClickHandle = async (type, value) => {    
    let item = {
        turnOnDate: data?.turnOnDate,
        tempActive: data?.ac?.tempActive, 
        modeActive: data?.ac?.modeActive, 
        fanSpeedActive: data?.ac?.fanSpeedActive, 
        command: null
    }

    if(type === 'on')
        item.turnOnDate = moment()
    else if(type === 'off')
        item.turnOnDate = null
    else if(type === 'temp') {
        item.tempActive = value
    }
    else if(type === 'mode'){
        item.modeActive = value
    }
    else if(type === 'fan'){
        item.fanSpeedActive = value
    }

    item.command = await createCommand(data, type, value)

    if(item.command)
        sendCommand(item)
    else
        dispatch(setShowMassage({massage: "ERROR",type: "error"}));
  }

const powerkHandle = async (value) => {
     onClickHandle(value, value)
};

const findNextTemp = async (direction) => {
    let value;
    let nextIndex;

    const keys = Object.keys(data?.ac?.commands?.temperatures); 
    const index = keys.indexOf(data?.ac?.tempActive); 

    if (index === -1) {
        value = 'T16'
    }

    if (direction === "up") {
        nextIndex = index + 1; 
    } else if (direction === "down") {
        nextIndex = index - 1; 
    } else {
        console.error("Error: Invalid direction. Use 'up' or 'down'.");
        return null;
    }

    if (nextIndex < 0 || nextIndex >= keys.length) {
        console.error("Error: No more temperatures in the given direction.");
        return null;
    }

    value = keys[nextIndex];
     onClickHandle("temp", value)

};

const findNextMode = async () => {
    let value;
    let nextIndex;

    const keys = Object.keys(data?.ac?.commands?.modes); // מקבל את כל המפתחות
    const index = keys.indexOf(data?.ac?.modeActive); // מיקום המפתח הנוכחי

    if (index === -1) {
        value = keys[0]
    } else if(keys[index + 1])  {
        nextIndex = index + 1; // עבור "up", נעבור לאינדקס הבא
        value = keys[nextIndex]; // מפתח הבא
    } else {
        value = keys[0]
    }
     onClickHandle("mode", value)
};

const findNextFan = async () => {
    let value;
    let nextIndex;

    const keys = Object.keys(data?.ac?.commands?.fanSpeed); // מקבל את כל המפתחות
    const index = keys.indexOf(data?.ac?.fanSpeedActive); // מיקום המפתח הנוכחי

    if (index === -1) {
        value = keys[0] 
    } else if(keys[index + 1])  {
        nextIndex = index + 1;
        value = keys[nextIndex]; // מפתח הבא
    } else {
        value = keys[0]
    }
    onClickHandle("fan", value)
};

    return (
        <Stack justifyContent='center' alignItems='center' spacing={3}>
            <Stack 
                width={200} 
                height={150}
                direction='row' 
                borderRadius={5} 
                border={2}
                justifyContent='space-between'
            >
                
                <Stack paddingBottom={0.5} position='relative' justifyContent='end' alignItems='center'>
                    <AutofpsSelect sx={{transform: 'translate(0px, 20px)', fontSize: 35, opacity: fanAutoFanOpacity}}/>
                    <KeyboardDoubleArrowUp sx={{transform: 'translate(0px, 20px)', fontSize: 40, opacity: fanHighOpacity}}/>
                    <KeyboardDoubleArrowUp sx={{transform: 'translate(0px, 10px)', fontSize: 40, opacity: fanMediumOpacity}}/>
                    <KeyboardDoubleArrowUp sx={{fontSize: 40, opacity: fanLowOpacity}}/>
                </Stack>

                <Divider orientation="vertical" flexItem />

                <Stack 
                    sx={{opacity: fanEmpOpacity}} 
                    width='100%' 
                    alignItems='center' 
                    justifyContent='center'
                >
                    <Typography variant="h6">
                    {`${t("TEMP_ACTIVE")}`}
                    </Typography>
                    <Typography variant="h3" fontWeight='bold'>
                    { data?.ac?.tempActive ? `${data?.ac?.tempActive?.slice(1)}°` : "----"}
                    </Typography>
                </Stack>

                <Divider orientation="vertical" flexItem />

                <Stack padding={0.5} position='relative' justifyContent='space-between' alignItems='center'>
                    <AutofpsSelect sx={{fontSize: 35, opacity: modeAutoOpacity}}/>
                    <WindPower sx={{fontSize: 25, opacity: modeVentOpacity }}/>
                    <LightMode sx={{fontSize: 25, opacity: modeHeatOpacity }}/>
                    <AcUnit sx={{fontSize: 25, opacity: modeCoolOpacity}}/>
                </Stack>

            </Stack>

            <Button 
                color={ data?.turnOnDate ? 'error' : 'success' }
                variant="contained" 
                fullWidth
                onClick={() => {
                    const value = data?.turnOnDate ? 'off' : 'on'
                    powerkHandle(value)
                }}
            >
                <SettingsPower fontSize="large"/>
            </Button>

            <Stack justifyContent='center' alignItems='center' spacing={1}>
                <CustomIconButton
                    disabled={!data?.turnOnDate}
                    icon={<ExpandLess fontSize="large"/>}
                    onClick={() => findNextTemp('up')}
                 />

                <Stack direction='row' spacing={7}>
                    <CustomIconButton
                        disabled={!data?.turnOnDate}
                        onClick={findNextFan}
                        icon={<WindPower fontSize="large"/>}
                        />
                    <CustomIconButton 
                        disabled={!data?.turnOnDate}
                        onClick={findNextMode} 
                        icon={<ModeStandby fontSize="large"/>}
                    />
                </Stack>

                <CustomIconButton
                    disabled={data?.ac?.tempActive === "T16" || !data?.turnOnDate}
                    icon={<ExpandMore fontSize="large"/>}
                    onClick={() => findNextTemp('down')}
                />

            </Stack>
        </Stack>
    )
}
export default AcCommands