import TextInput from "../../../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../../../http";
import { setShowLoading, setShowMassage } from "../../../../store/sysSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../../../config/settings";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../components/CustomButton";
import { endpointName } from "../const";
import CustomIconButton from "components/CustomIconButton";
import { Close } from "@mui/icons-material";
import CustomTabs from "components/CustomTabs";
import Global from "./Global";
import PowerCommands from "./PowerCommands";
import TempsCommands from "./TempsCommands";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Details = ({ acCommand, setList, getData }) => {
  const theme = useTheme()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {reset,handleSubmit,control,formState: { errors }} = useForm();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {title:'GLOBAL' , show: true},
    {title:'POWER_COMMANDS' , show: true},
    {title:'TEMPS_COMMANDS' , show: true},
]

  useEffect(() => {
    if (acCommand && acCommand?.id !== "new") {
      const commands = acCommand?.commands
      reset({
        ...acCommand,
        onComm1: commands?.power?.on?.comm1,
        onComm2: commands?.power?.on?.comm2, 
        onComm3: commands?.power?.on?.comm3,
        offComm1: commands?.power?.off?.comm1,
        offComm2: commands?.power?.off?.comm2, 
        offComm3: commands?.power?.off?.comm3,

        ventLow: commands?.modes?.vent?.low,
        ventMedium: commands?.modes?.vent?.medium,
        ventHigh: commands?.modes?.vent?.high,
        ventTempException: commands?.modes?.vent?.tempException,

        coolLow: commands?.modes?.cool?.low,
        coolMedium: commands?.modes?.cool?.medium,
        coolHigh: commands?.modes?.cool?.high,

        heatLow: commands?.modes?.heat?.low,
        heatMedium: commands?.modes?.heat?.medium,
        heatHigh: commands?.modes?.heat?.high,

        T16: acCommand?.commands?.temperatures?.T16?.command,
        T17: acCommand?.commands?.temperatures?.T17?.command,
        T18: acCommand?.commands?.temperatures?.T18?.command,
        T19: acCommand?.commands?.temperatures?.T19?.command,
        T20: acCommand?.commands?.temperatures?.T20?.command,
        T21: acCommand?.commands?.temperatures?.T21?.command,
        T22: acCommand?.commands?.temperatures?.T22?.command,
        T23: acCommand?.commands?.temperatures?.T23?.command,
        T24: acCommand?.commands?.temperatures?.T24?.command,
        T25: acCommand?.commands?.temperatures?.T25?.command,
        T26: acCommand?.commands?.temperatures?.T26?.command,
        T27: acCommand?.commands?.temperatures?.T27?.command,
        T28: acCommand?.commands?.temperatures?.T28?.command,
        T29: acCommand?.commands?.temperatures?.T29?.command,
        T30: acCommand?.commands?.temperatures?.T30?.command,
      });
      setData(acCommand);
    }
  }, [acCommand]);

  const onClose = () => {
    setList(false);
  };

  const onSubmit = (values) => {
    if(!values?.brand || !values?.model ||!values?.protocol || !values?.bits)
      return dispatch(setShowMassage({massage: "SET_ALL_ASTERISK_ERROR",type: "error"}));

    let data = {
      brand: values?.brand,
      model: values?.model,
      version: values?.version,
      year: values?.year,
      region: values?.region, 
      protocol: values?.protocol,
      startCommand:values?.startCommand,
      endCommand:values?.endCommand,
      sysName:values?.sysName,
      bits: values?.bits,
      commands: {
        power: { 
          on: { comm1: values?.onComm1, comm2: values?.onComm2, comm3: values?.onComm3},
          off: { comm1: values?.offComm1, comm2: values?.offComm2, comm3: values?.offComm3},
        },
        modes: {
          vent: {
            low: values?.ventLow, 
            medium: values?.ventMedium,
            high: values?.ventHigh,
            tempException:values?.ventTempException
          },
          cool: {low: values?.coolLow, medium: values?.coolMedium,high: values?.coolHigh},
          heat: {low: values?.heatLow, medium: values?.heatMedium,high: values?.heatHigh},
        },
        temperatures: {
          T16:{command: values?.T16},
          T17:{command: values?.T17},
          T18:{command: values?.T18},
          T19:{command: values?.T19},
          T20:{command: values?.T20},
          T21:{command: values?.T21},
          T22:{command: values?.T22},
          T23:{command: values?.T23},
          T24:{command: values?.T24},
          T25:{command: values?.T25},
          T26:{command: values?.T26},
          T27:{command: values?.T27},
          T28:{command: values?.T28},
          T29:{command: values?.T29},
          T30:{command: values?.T30},
        },
        fanSpeed: {low: "low", medium: "medium", high: "high"},
      },
    };

    if (acCommand?.id !== "new") {
      http.patch(`${apiUrl}/${endpointName}/${acCommand?.id}`, data).then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    } 
    
    else {
      http.post(`${apiUrl}/${endpointName}`, data).then((res) => {
          dispatch(setShowLoading(false));
          getData();
          onClose();
        })
        .catch((error) => {
          dispatch(setShowLoading(false));
        });
    }
  };

  if (!data && acCommand?.id !== "new") {
    return;
  }

  return (
    <Dialog
      open={acCommand ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "scroll" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
            padding: 0,
          }}>
          <Stack spacing={1}>
            <Stack
              padding={2}
              direction='row' 
              alignItems='center' 
              justifyContent='space-between' 
              spacing={1}
            >
            <Stack alignItems='center' direction='row' spacing={1}>
              <Typography variant="h6">
                {`${t("SERVICES_CALL")}`}
              </Typography>
            </Stack>

            <CustomIconButton
              size={30}
              icon={<Close/>} 
              onClick={onClose}
            />
            </Stack>

            <CustomTabs
            tabList={tabs}
            scrollAmount={20}
            callBackFunc={(e) => setSelectedTab(e)}
            showScrollBtn={isMobile ? true : false }
            />
            </Stack>
        </DialogTitle>  

        <DialogContent dividers>

          {selectedTab === 0
          ? <Global control={control} errors={errors} acCommand={acCommand}/>
          : selectedTab === 1 
          ? <PowerCommands control={control} errors={errors}/>
          : selectedTab === 2
          ? <TempsCommands control={control} errors={errors}/>
          : null
          }

        </DialogContent>

        <DialogActions 
         sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "white",
          zIndex: 999,
          borderTop: "1px solid black",
          pt: 3,
        }}
        >
          <CustomButton
            variant="outlined"
            title="CANCEL"
            autoFocus
            onClick={onClose}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
