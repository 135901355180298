import { useTranslation } from "react-i18next";
import {MenuItem, Select, Stack, Typography} from "@mui/material";
import { useEffect, useState } from "react";
import { apiUrl } from "config/settings";
import http from 'http'
import { useDispatch } from "react-redux";
import { setShowLoading } from "store/sysSlice";

const Header = ({area, selectedArea}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [areas, setAreas] = useState([])

    useEffect(() => {
      getAreas()
    }, [])
  

    const getAreas = () => {
      dispatch(setShowLoading(true));
      http.get(`${apiUrl}/area`).then((res) => {
        setAreas(res?.data?.data);
        dispatch(setShowLoading(false));
      });
    };
  
  return (
    <Stack width="55%" direction="row" justifyContent="space-between">

        <Select
          sx={{ width: 250 }}
          size="small"
          MenuProps={{PaperProps: {style: {maxHeight: 150,width: 250}}}}
          value={area}
          defaultValue={'all'}
          onChange={(e) => selectedArea(e.target.value)}
        >
          <MenuItem value={"all"}>
            <Typography fontWeight="bold">{t("ALL_AREAS")}</Typography>
          </MenuItem>

          {areas?.map((area, index) => {
            return (
              <MenuItem key={index} value={area?.id}>
                <Typography>{area?.name}</Typography>
              </MenuItem>
            );
          })}
        </Select>
    </Stack>
  );
};
export default Header;
